.buttonCell {
  background-color: transparent;
  border: none;
  outline: none;
}

.pgCell {
  align-items: center;
  display: grid;
  grid-template-columns: 30px calc(100% - 30px);
  column-gap: 1px;
  width: 100%;
  svg {
    cursor: pointer;
    fill: #B8B8B8;
    width: 25px;
    height: 25px;
  }
}

.linkCell {
  font-weight: 600;
  color: #4e9ae5;
  text-decoration: none;
  &:hover {
    color: darken(#4e9ae5, 10%);
    transition: all 0.2s ease-in;
  }
}

.coloredCell {
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  &_error {
    color: red;
  }
  &_success {
    color: green;
  }

  &_info {
    color: blue;
  }
}

.actionCell {
  cursor: pointer;
  width: 100%;
  text-align: center;
  color: #888;
  &_remove {
    color: red;
  }
}

.preShowSticker {
  width: 100%;
  img {
    width: 100%;

  }
}

.tooltipCell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
