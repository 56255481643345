.languageSwitcher {
  button {
    color: #707070 !important;
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-transform: uppercase;
  }
  span {
    margin: 0 !important;
  }
  svg {
    fill:  #707070 !important;
  }
}

.language {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding-left: 12px;
  align-items: center;
  display: flex;
  svg {
    width: 20px;
    height: 20px;
    transition: all 0.2s ease-in;
  }
}

.language[active] {
  svg {
    transform: rotate(180deg);
  }
}
