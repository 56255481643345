.dataTableWrap {
  position: relative;
  button {
    svg {
      fill: #d3d3d3;
    }
  }
  div[role=table] > div:nth-child(1) > div[role='row'] {
    cursor: default !important;
  }
  div[role='row'] {
    background-color: #f9f9f9;
    cursor: default;
  }
  div[role='row']:hover {
    background-color: #f9f9f9 !important;
    //cursor: pointer;
  }
  div[role='rowgroup'] div[role='row'] {
    background-color: #fff;
  }
  button {
    color: white;
  }
  img {
    display: none;
  }
  .emptyTable {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &_button {
      height: 70px;
      font-size: 14px;
      line-height: 1.4285714285714286;
      padding-left: 40px;
      padding-right: 40px;
      color: white;
      background: #67ac5c;
      border: none;
      border-radius: 4px;
      letter-spacing: .02em;
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
    }
    &_text {
      font-size: 18px;
      line-height: 1.33333;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      margin-bottom: 24px;
    }
  }
}

.theme-kz button[style*='background: rgb(246, 244, 46)'] {
   background-color: #0287B0 !important;
   color: white !important;;
}

.theme-uz button[style*='background: rgb(246, 244, 46)'] {
   background-color: #67ac5c !important;
   color: white !important;;
}

.theme-kg button[style*='background: rgb(246, 244, 46)'] {
  background-color: #057EC6 !important;
  color: white !important;;
}
.theme-am button[style*='background: rgb(246, 244, 46)'] {
  background-color: #707070 !important;
  color: white !important;;
}

button[type='submit'] {
  color: white;
}
