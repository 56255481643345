body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.spinnerWrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datePickerField {
  margin-top: 16px;
  label {
    transform: translate3d(16px, 8px, 0) scale(0.85714) !important;
  }
}

.uploadBtn {
  & > span {
    width: 50px;
    height: 48px !important;
    padding: 0;
  }
}

.fileLinkWrap {
  width: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.fieldArray {
  &__actions {
    padding-top: 15px;
  }
  &__deviceActions {
    padding-top: 20px !important;
  }
  button {
    padding-left: 10px !important;
    padding-right: 10px !important;;
    height: 48px !important;
  }
}

.infoItem {
  div {
    background-color: #f9f6f6 !important;
  }
}

.hiddenHeaderTable {
  div[role='table'] > div:nth-child(1) {
    display: none;
  }
}

.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.productViewTable {
  margin-left: -30px;
  margin-top: -30px;
  margin-right: -30px;
  h2 {
    color: #63666a;
    font-size: 20px;
    text-align: center;
    margin-top: 50px;
  }
}

.aggregationTable {
  div[role="cell"] {
    padding: 21px 10px;
  }
  div[role="row"] {
    top: 0 !important;
    height: auto !important;
    position: relative !important;
  }
}

.flexLine {
  display: flex;
  align-items: center;
}

.uploadBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.printButton {
  display: flex;
  justify-content: center;
  width: 100%;
  button {
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
  }
  svg {
    font-size: 30px;
  }
  &__disabled {
    opacity: 0.5;
    button {
      cursor: default;
    }
  }
}

.selectField {
  margin-top: 16px;
  label {
    transform: translate3d(16px, 8px, 0) scale(0.85714) !important;
  }
}

.spTable {
  div[role="rowgroup"] {
    height: calc(100vh - 420px) !important;
  }
}

.serviceProvider button{
  margin-top: 15px;
  height: 49px !important;
}

.productDraftTable {
  padding: 30px;
}

.logoUzSmall {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.clue {
  font-size: 16px;
  margin-left: 6px;
  color: #63666a;
}

.smallClue {
  font-size: 13px;
  color: #63666a;
}

.normalText {
  text-transform: none;
}