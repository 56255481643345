@import '../../variables';

.layout {
  display: grid;
  grid-template-columns: 80px calc(100% - 80px);
  grid-template-rows: 65px calc(100% - 65px);
  grid-template-areas: 'nav header'
  'nav main';
  .header {
    grid-area: header;
    z-index: 1;
    button {
      //background-color: inherit;
    }
  }
  .nav {
    grid-area: nav;
    //z-index: 12;
  }
  .main {
    grid-area: main;
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
  .page {
    height: auto;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    &__content {
      border: 1px solid $light-grey-color;
      box-shadow: $block-shadow;
      position: relative;
      background-color: #fff;
      //height: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
  }
}
