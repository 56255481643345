.alertContainer {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1000;
  top: 24px;
  right: 24px;
  div {
    position: relative;
    margin-bottom: 5px;
  }
}
